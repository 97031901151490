<template>
  <footer>
    <h1>
      <router-link to="/">
        MAKE<br />
        GREATNESS<br />
        <span class="e-motion">e <span>.</span> motion</span>
      </router-link>
    </h1>
    <privacy :dialogVisible.sync="dialogVisible" />
    <a href="#" @click.prevent="dialogVisible = true">개인정보취급방침</a>
    <p class="copy">ⓒ e <span>.</span> motion Global Inc. All rights reserved.</p>
    <button type="button" class="btn-top" @click="winTop">top</button>
  </footer>
</template>
<script>
  import privacy from "@/views/privacy";
  import bus from "@/utils/bus";
  export default {
    name: "AppFooter",
    data() {
      return {
        dialogVisible: false
      };
    },
    components: {
      privacy
    },
    props: ["smoothScrollBar"],
    methods: {
      winTop() {
        bus.$emit("winScrollTo", 0, 0);
      }
    }
  };
</script>
<style lang="scss" scoped>
  footer {
    @include globalWidth(min-width);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 136px;
    width: calc(100% - 120px);
    background: #fff;
    h1 {
      @include montserrat();
      font-size: 15px;
      line-height: 22px;
      color: $red;
      .e-motion {
        font-size: 18px;
        span {
          line-height: 0;
          vertical-align: 0.2em;
        }
      }
      a {
        color: $red;
      }
    }
    a {
      font-size: 12px;
      color: #a0a0a0;
      margin-left: auto;
    }
    .copy {
      @include montserrat();
      margin-left: 30px;
      font-size: 13px;
      color: #a0a0a0;
      span {
        line-height: 0;
        vertical-align: 0.2em;
      }
    }
    .btn-top {
      height: 60px;
      width: 60px;
      margin-left: 60px;
      border: none;
      text-indent: -9999px;
      overflow: hidden;
      cursor: pointer;
      outline: none;
      background: url("../assets/images/btn_top.png") center no-repeat;
    }
  }
</style>
